import React, { useEffect } from "react";

import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

function TermsandCondition() {
   useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  return (
   <>
    <Navbar/>
    <div className='Terms'>
<h1>Terms <span>and Conditions</span></h1>
<br/>
<p>Welcome to THE JUICES, a company which provides fresh cold pressed Juices and fruit bowls
delivered to your doorstep everyday on subscription basis. These terms and conditions govern
your use of our website and the purchase of our products. By using our website, you agree to
these terms and conditions.
</p>
    </div>
    <br/>
    <div className='Terms2'>
<h2>Ordering and Payment :</h2>
<br/>


<p>When you make a purchase from our website, you agree to provide accurate and complete
information, including your name, Delivery address, and payment information. You also agree to
pay for the products that you have ordered, including any applicable taxes and shipping fees.</p>
<br/>
<h2>Pricing and Availability:</h2>
<br/>
<p>All prices on our website are in INR and we reserve the right to change our prices at any time
without notice. We also reserve the right to modify or discontinue any products without notice</p>
<br/>
<h2>Intellectual Property:</h2>
<br/>

<p>All content on our website, including images, text, and logos, is the property of THE JUICES
and is protected by copyright laws. You may not use our content without our express written
consent.
</p>
   <br/>
<h2>Disclaimer of Warranties:</h2>
<br/>

<p>We make no warranties or representations about the accuracy or completeness of the
information on our website, and we are not responsible for any errors or omissions. We do not
warrant that our website will be error-free or uninterrupted, or that any defects will be corrected.
</p>
   <br/>
<h2>Limitation of Liability:</h2>
<br/>

<p>We are not liable for any damages, including but not limited to lost profits or lost data, arising
from your use of our website or your purchase of our products
</p>
   <br/>
<h2>Governing Law:</h2>
<br/>

<p>These terms and conditions are governed by the laws of Telangana Government, and any
disputes arising from your use of our website or your purchase of our products will be subject to
the exclusive jurisdiction of the courts of the state.
</p>
   <br/>
<h2>Changes to These Terms:</h2>
<br/>

<p>We reserve the right to update or modify these terms and conditions at any time without notice.
Your continued use of our website after any changes or modifications indicates your acceptance
of these terms and conditions</p>
   
    </div>
    <Footer/>
   </>
  )
}

export default TermsandCondition