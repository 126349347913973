import React, { useEffect, useState } from "react";
import newbanner from "../Assets/newbanner.png";

const SmallCom = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    const storedProduct = JSON.parse(localStorage.getItem("selectedProduct"));
    if (storedProduct) {
      setSelectedProduct(storedProduct);
    }
  }, []);
  return (
    <div className="stp">
      <h2 className="step-product-name" style={{textAlign:'center'}}>{selectedProduct?.name}</h2>
      <br/>
      <div className="slot-banner">
        <img src={selectedProduct?.imageUrl||newbanner} alt="Banner" width={"200px"} height={"200px"} style={{borderRadius:'10px'}}/>
        <div className="slot-banner-up" style={{textAlign:"center"}}>
          <p>{selectedProduct?.subscriptionType=="Monthly"?"1 Month":"Trial"}</p>
          <h3>
            Rs {selectedProduct?.subscriptionType=="Monthly"?selectedProduct?.price:selectedProduct?.trialPack}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default SmallCom;
