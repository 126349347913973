import React, { useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import img from "../Assets/Screenshot_2024-07-18_105608-removebg-preview.png";
import { useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";
import axios from "axios";
import { baseUrl } from "../utils/data";
import { toast } from "react-toastify";

function Profile() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(1);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [deliveryAddress, setdeliveryAddress] = useState("");

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      setName(userData.name);
      setEmail(userData.email);
      setMobile(userData.mobile);
      setdeliveryAddress(userData.deliveryAddress);
    } else {
      navigate("/Login");
    }
  }, [navigate]);

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const handleLogOut = () => {
    localStorage.clear();
    navigate("/Login");
  };

  const updateUser = async () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (!userData) {
      toast.error("Please Login First.");
    }
    if (!name || !email) {
      alert("Both name and email are required");
      return;
    }

    try {
      const res = await axios.put(
        `${baseUrl}/user/update`,
        { name, email, mobile, userId: userData?._id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (res?.status == 200) {
        toast.success("Profile updated successfully");
        console.log(res);
        localStorage.setItem("userData", JSON.stringify(res?.data?.data?.user));
        navigate(-1)
      }
    } catch (error) {
      toast.error("Error updating profile");
    }
  };

  return (
    <>
      <Navbar />
      <div className="profile">
        <img src={img} alt="Profile" />
        <h1>Hello {name}</h1>
        <p>Welcome to your Account</p>

        <br />
        <br />

        <div className="tab-container">
          <div className="tabs">
            <button
              className={activeTab === 1 ? "tab-btn active" : "tab-btn"}
              onClick={() => handleTabChange(1)}
            >
              Personal Info
            </button>
            <button
              className={activeTab === 2 ? "tab-btn active" : "tab-btn"}
              onClick={() => handleTabChange(2)}
            >
              My Orders
            </button>
            {/* <button
              className={activeTab === 3 ? "tab-btn active" : "tab-btn"}
              onClick={() => handleTabChange(3)}
            >
              Wishlist
            </button> */}
          </div>
        </div>
      </div>
      <div className="tab-content2">
        {activeTab === 1 && (
          <>
            <div className="personal-info">
              <h2>My Personal Info</h2>
              <p>Contact Details</p>
            </div>
            <br />
            <div className="detail-info">
              <div>
                <p>Your Name</p>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <br />
            <div className="line2"></div>
            <br />

            <div className="detail-info">
              <div>
                <p>Your Email</p>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <br />
            <div className="line2"></div>
            <br />

            {/* <div className="detail-info">
              <div>
                <p>Your Address</p>
                <input
                  type="text"
                  value={deliveryAddress}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            <br /> */}
            {/* <div className="line2"></div> */}
            <br />
            <div className="detail-info">
              <div>
                <p>Your Phone</p>
                {/* <input
      type="number"
      value={mobile}
      onChange={(e) => setMobile(e.target.value)}
    /> */}
                <h3>{mobile}</h3>
              </div>
            </div>

            <br />
            <div className="line2"></div>
            <br />
            <div className="center">
              <button onClick={updateUser}>SAVE CHANGES</button>
              <button style={{ width: "150px" }} onClick={handleLogOut}>
                Logout
              </button>
            </div>
          </>
        )}
        {activeTab === 2 && (
          <>
            <div className="personal-info">
              <h2>My Orders</h2>
            </div>
          </>
        )}
        {activeTab === 3 && (
          <>
            <div className="personal-info">
              <h2>My Wishlist</h2>
            </div>
          </>
        )}
      </div>
      <Footer />
    </>
  );
}

export default Profile;
