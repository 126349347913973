import React from 'react'
import logo from '../Assets/newlogo.png'
import pay from '../Assets/payment-group.png.svg'
import icon1 from '../Assets/social (1).svg'
import icon2 from '../Assets/social (2).svg'
import icon3 from '../Assets/social (3).svg'
import icon4 from '../Assets/social (4).svg'
import btn1 from '../Assets/store btn (1).svg'
import btn2 from '../Assets/store btn (2).svg'
import { Link } from 'react-router-dom'



function Footer() {
  return (
   <>
  <div className='footer'>
    <ul className='f1'>
      <img className='logo logo2' src={logo}/>
      <br/>
      <br/>
      {/* <p>Donec sit amet nibh
vestibulum ipsum cursus
rhoncus. Duis ac tortor
gravida ligula eleifend finibus
sed vel tellus.</p> */}
<br/>
<div className='social-btn'>

  <div className='socialback'>
  <i class="bi bi-facebook"></i>
  </div>
  <Link to='https://api.whatsapp.com/send?phone=918977037763'>

  <div className='socialback'>
  <i class="bi bi-whatsapp"></i>
  </div>
  </Link>
  <div className='socialback'>
  <i class="bi bi-instagram"></i>
  </div>
  <div className='socialback'>
  <i class="bi bi-twitter"></i>
  </div>
  <div className='socialback'>
  <i class="bi bi-linkedin"></i>
  </div>
  <div className='socialback'>
  <i class="bi bi-youtube"></i>
  </div>

</div>
    </ul>
    <ul className='f1'>
      <h3>Get In Touch</h3>
      <br/>

      <p>The Juices, 100 feet road, Madhapur, Hyderabad, 500081.</p>
      <br/>
      <p>+91 8977037763</p>
      <br/>
      <p>thejuices.co@gmail.com</p>
      <br/>
      <div className='social-btn'>
      <img src={btn1}/>
      <img src={btn2}/>

      </div>

    </ul>
    <ul className='f2 f3'>
      <h3>Policies </h3>
      <br/>
      <Link className='links' to='/termsncondition'>

      <li>
      Terms and condition
      </li>
      </Link>
      <Link className='links' to='/privacypolicy'>

<li>
Privacy Policy
</li>
</Link>
      <Link className='links' to='/shippingpolicy'>

      <li>
      Shipping Policy
      </li>

      </Link>

      <Link className='links' to='/returnnrefund'>


      <li>
      Return and Refund
      </li>
      </Link>
     

    </ul>
 
  </div>
  <div className='bottom-footer'>
  <p>© The Juices All rights Reserved. Designed & Developed by <span style={{fontWeight:'600'}}><Link style={{textDecoration:'none', color:'black'}} to='https://www.strixdigital.in/'>Strix Digital</Link></span></p>
  {/* <img src={pay}/> */}

  </div>
   </>
  )
}

export default Footer