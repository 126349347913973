import React, { useEffect, useState } from "react";
import newbanner from "../Assets/newbanner.png";
import morning from "../Assets/morn.png";
import evening from "../Assets/eve.png";
import axios from "axios";
import { toast } from "react-toastify";
import { baseUrl } from "../utils/data"; // Make sure this path is correct
import SmallCom from "./SmallCom";

const StepOne = ({ step, handleNext }) => {
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [slot, setSlot] = useState("");
  const [startDate, setStartDate] = useState("");

  console.log(slot);
  useEffect(() => {
    const storedProduct = JSON.parse(localStorage.getItem("selectedProduct"));
    if (storedProduct) {
      setSelectedProduct(storedProduct);
    }
    if (storedProduct.slot) {
      setSlot(storedProduct.slot);
    }
  }, []);

  useEffect(() => {
    if (selectedProduct) {
      // Add or update the subscriptionType in the product data
      const updatedProduct = { ...selectedProduct, slot };
      localStorage.setItem("selectedProduct", JSON.stringify(updatedProduct)); // Save the updated product to
      setSelectedProduct(updatedProduct)
    }
  }, [slot]);

  useEffect(() => {
    if (selectedProduct) {
      const updatedProduct = { ...selectedProduct, startDate };
      localStorage.setItem("selectedProduct", JSON.stringify(updatedProduct));
      setSelectedProduct(updatedProduct)
    }
  }, [startDate]);

  const handleSendOtp = async () => {
    if (mobile.length !== 10) {
      return toast.error("Mobile Number should be 10 digits.");
    }
    setLoading(true);
    try {
      const response = await axios.post(`${baseUrl}/auth/login`, { mobile });
      if (response.status === 200) {
        setOtpSent(true);
        toast.success("OTP sent successfully");
        handleNext();
      }
    } catch (error) {
      toast.error("Failed to send OTP");
    }
    setLoading(false);
  };

  const handleVerifyOtp = async () => {
    if (otp.length !== 4) {
      return toast.error("OTP should be 4 digits.");
    }
    setLoading(true);
    try {
      const response = await axios.post(`${baseUrl}/auth/verify`, {
        mobile,
        otp
      });
      if (response.status === 200) {
        toast.success("Login successful");
        // Store user data and token in local storage if needed
        localStorage.setItem(
          "userData",
          JSON.stringify(response.data.data.user)
        );
        localStorage.setItem("token", response.data.data.token);
        handleNext(); // Proceed to the next step after successful verification
      }
    } catch (error) {
      toast.error("Invalid OTP");
    }
    setLoading(false);
  };

  return (
    <>
      {step === 1 && (
        <div className="mission-clip4">
          <SmallCom />
          {/* <h2>{selectedProduct.name}</h2> */}
          {/* <div className="slot-banner">
            <img src={newbanner} alt="New Banner" />
            <div className="slot-banner-up">
              <p>Trail / 1m</p>
              <h3>Rs 650 / 2599</h3>
            </div>
          </div> */}
          <br/>
          <div className="mission-slot-day-eveing">
            <h3 style={{ textAlign: "left" , color:'black' }}>Slot</h3>
            <div className="radio-buttons">
              <input
                type="radio"
                id="option1"
                name="radio-group"
                className="radio-input"
                value="Morning"
                checked={slot === "Morning"}
                onChange={() => setSlot("Morning")}
              />
              <label htmlFor="option1" className="radio-label">
              <i class="bi bi-brightness-high"></i>
                Morning
              </label>

              <input
                type="radio"
                id="option2"
                name="radio-group"
                className="radio-input"
                value="Evening"
                checked={slot === "Evening"}
                onChange={() => setSlot("Evening")}
              />
              <label htmlFor="option2" className="radio-label">
              <i class="bi bi-brightness-alt-high"></i>
                Evening
              </label>
            </div>

            <h3 style={{ textAlign: "left",  color:'black' , marginTop:'25px'}}>Date</h3>
            <input
            className="selectdate"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />

            <h3 style={{ textAlign: "left", color:'black', marginTop:'25px' }}>Login/Sign up</h3>
            <input
            className="selectdate"

              type="text"
              placeholder="Enter Number"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
          </div>
<br/>
          <button
            className="missionloginbtn"
            onClick={handleSendOtp}
            disabled={loading}>
            Send OTP {loading && <span>Loading...</span>}
          </button>
        </div>
      )}

      {step === 2 && (
        <div className="stp2 mission-clip4">
          {/* <h2>{selectedProduct.name}</h2>
          <div className="slot-banner">
            <img src={newbanner} alt="New Banner" />
            <div className="slot-banner-up">
              <p>Trail / 1m</p>
              <h3>Rs 650 / 2599</h3>
            </div>
          </div> */}
          <SmallCom />

          <div className="mission-slot-day-eveing">
            <h3 style={{ textAlign: "left" }}>Verify Details</h3>
            <div className="verify-otp">
              {Array.from({ length: 4 }).map((_, index) => (
                <input
                  key={index}
                  type="text"
                  value={otp[index] || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    const newOtp = otp.split("");
                    newOtp[index] = value;
                    setOtp(newOtp.join(""));
                  }}
                  maxLength={1}
                />
              ))}
            </div>
            <p className="otptime">1 min 57 secs Resend OTP</p>
          </div>

          <button
            className="missionloginbtn"
            onClick={handleVerifyOtp}
            disabled={loading}>
            Verify OTP {loading && <span>Loading...</span>}
          </button>
        </div>
      )}
    </>
  );
};

export default StepOne;
