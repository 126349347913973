import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import img1 from "../Assets/image 206.png";
import { baseUrl } from "../utils/data";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";
import AddressDialog from "../Components/AddressDialog"; // Import AddressDialog
import { Button } from "@mui/material";
import SmallScreenLoader from "../loader/SmallScreenLoader";
import load from '../Assets/newlogo.png'
function Shipping() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [total, setTotal] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [currentItemIndex, setCurrentItemIndex] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [load1, setLoad1] = useState(false)

  const fetchAddresses = async () => {
    try {
      const response = await axios.get(`${baseUrl}/user/bytoken`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setAddresses(response?.data?.data?.deliveryAddress);
    } catch (error) {
      console.error("Error fetching addresses:", error);
    }
  };
  // console.log(addresses);

  const handleIncrement = () => setQuantity(quantity + 1);
  const handleDecrement = () => setQuantity(quantity > 1 ? quantity - 1 : 1);

  const fetchCartData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/newcart/getcartbyuser`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setCartItems(response?.data?.data?.items);
    } catch (error) {
      console.error("Error fetching cart data:", error);
      if (error?.response?.data?.message === "User not authorized") {
        toast.error("Please login again.");
        navigate("/Login");
      }
    } finally {
      setLoading(false); // Set loading to false once data is fetched
    }
  };

  useEffect(() => {
    fetchCartData();
    fetchAddresses();
  }, []);

  const handleTotal = () => {
    const totalAmount = cartItems.reduce(
      (acc, cur) => acc + cur?.orderAmount + cur?.packingAmount,
      0
    );
    setTotal(totalAmount);
  };

  useEffect(() => {
    handleTotal();
  }, [cartItems]);

  const handleSelectAddress = async (addressId) => {
    try {
      // console.log(addresses);
      // console.log(addressId);
      const selectedAddress = addresses.find(
        (address) => address._id === addressId
      );
      // console.log(selectedAddress);
      if (currentItemIndex !== null) {
        const updatedCartItems = [...cartItems];
        updatedCartItems[
          currentItemIndex
        ].deliveryAddress = `${selectedAddress.address}, ${selectedAddress.pincode}`;
        updatedCartItems[currentItemIndex].deliveryLocation =
          selectedAddress.location;
        setCartItems(updatedCartItems);
      }
    } catch (error) {
      console.error("Error updating cart item address:", error);
      toast.error("Failed to update cart item address");
    }
    setOpenDialog(false);
  };

  const handleSubscription = async () => {
    console.log("this is calling");
    if (
      cartItems.some((item) => !item.deliveryAddress || !item.deliveryLocation)
    ) {
      toast.error(
        "Please select delivery addresses for all items in your cart."
      );
      return;
    }
    if (total <= 0) {
      return toast.error("Amount is Greater than Zero");
    }
    setLoad1(true)
    try {
      const res = await axios.post(
        `${baseUrl}/subscription/newcreate`,
        { subscriptions: cartItems },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (res?.status == 200) {
        const orderId = res?.data?.data[0]?.batchId;
        console.log(res);
        const result = await axios.post(`${baseUrl}/phonepe/phoneperequest`, {
          amount: total,
          merchantUserId: orderId,
        });
        if (result?.status == 200) {
          window.location.href = result?.data;
        }
        // toast.success("Subscription Buy Successfully.");
        // navigate("/");
      }
    } catch (error) {
      toast.error("Buy Subscription Failed.");
    }
    setLoad1(false)
  };
  console.log(cartItems);

  if (loading) {
    return (
      <div className="loader-div">
        <br />
        <br />
        <img src={load} alt="Logo" class="loader-logo"/>
        <div class="spinner"></div>
        <br />
        <br />
      </div>
    );
  }
  return (
    <>
      <Navbar />
      {load1 && <SmallScreenLoader />}
      {/* <div className="pd-banner"></div> */}
      <br />
      <br />

      <h1 className="check-heading" style={{ textAlign: "center" }}>
        Checkout
      </h1>
      <div
        className="checkout-div-right"
        style={{
          width: "100%",

          alignContent: "center",
          alignItems: "center",
        }}
      >
        <h2>Your Cart</h2>
        <br />
        <div className="new-cart-cards">
          {cartItems.length > 0 &&
            cartItems.map((item, index) => (
              <div
                key={item.product._id}
                // style={{
                //   width: "100%",
                //   display: "grid",
                //   gridTemplateColumns: "repeat(4, 1fr)",
                //   margin: "20px",
                //   marginBottom: "20px",
                // }}
              >
                <div className="new-cart-card">
                  <img
                    src={item?.product?.imageUrl || img1}
                    alt={item?.product?.name}
                  />
                  <br />
                  <div
                    style={{
                      width: "100%",
                      height: "1px",
                      backgroundColor: "rgb(228, 228, 228)",
                    }}
                  ></div>
                  <br />

                  <h2>{item?.product?.name}</h2>

                  <div className="card-p">
                    <p>Slot: {item?.slot}</p>
                    {/* <p>
                      Packaging Type:{" "}
                      {item?.packageType == "onetimeuse"
                        ? "Plastic Bottle"
                        : "Glass Bottle"}
                    </p> */}
                    <p>
                      Start Date:{" "}
                      {new Date(item?.startDate)?.toLocaleDateString()}
                    </p>
                    <p>Price : {item?.orderAmount}</p>
                    {/* <p>Package Charge : {item?.packingAmount}</p> */}
                  </div>

                  <div
                    className="cart-item-text"
                    style={{ display: "flex", gap: "20px", width: "100%" }}
                  >
                    {/* <Button
                      onClick={() => {
                        setOpenDialog(true);
                        setCurrentItemIndex(index);
                      }}
                      className="newaddselect"
                    >
                      {item.deliveryAddress
                        ? "Change Address"
                        : "Select Address"}
                    </Button> */}
                    {item.deliveryAddress && <p>{item.deliveryAddress}</p>}
                  </div>
                </div>

                <br />
                <br />
              </div>
            ))}
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <div className="cart-total">
          <p>Subtotal ({cartItems.length || 0})</p>
          <h3>Rs {total}.00</h3>
        </div>
        <br />

        <div className="cart-total">
          <p>Tax</p>
          <h3>Rs 00.00</h3>
        </div>
        <br />

        <div className="cart-total">
          <p>Shipping</p>
          <h3>Free</h3>
        </div>
        <br />

        <div className="cart-total">
          <p>Total Orders:</p>
          <h3>Rs {total}.00</h3>
        </div>
      </div>
      <br />
      <br />
      <div className="continue-btn" style={{ justifyContent: "center" }}>
        <button className="CONTINUESHIPPING" onClick={handleSubscription}>
          Buy Subscription
        </button>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Footer />
      <AddressDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleSelectAddress={handleSelectAddress}
        fetchAddresses={fetchAddresses}
        addresses={addresses}
      />
    </>
  );
}

export default Shipping;

//  <div className="checkout-div">
//           <div className="checkout-div-left">
//             <div className="cont-info">
//               <h4>Contact</h4>
//               <p>Change</p>
//             </div>
//             <div className="line2"></div>
//             <div className="cont-info">
//               <h4>Ship To</h4>
//               <p>Change</p>
//             </div>
//             <br />
//             <h2>Delivery Options</h2>
//             <br />
//             <div className="line2"></div>

//             <div className="cont-info">
//               <h4>Ship To</h4>
//               <h6>Change</h6>
//             </div>
//             <div className="line2"></div>

//             <div className="cont-info">
//               <h4>Ship To</h4>
//               <h6>Change</h6>
//             </div>
//             <div className="line2"></div>

//             <div className="cont-info">
//               <h4>Ship To</h4>
//               <h6>Change</h6>
//             </div>

//             <br />
//             <br />
//             <div className="continue-btn">
//               <button className="Return">
//                 {" "}
//                 <img src={arrow} /> Return to cart
//               </button>

//               <button className="CONTINUESHIPPING">CONTINUE TO SHIPPING</button>
//             </div>
//           </div>
//           <div className="checkout-div-right">
//             <h2>Your Cart</h2>
//             <br />

//             <div className="cart-item">
//               <img src={img1} />
//               <div className="cart-item-text">
//                 <h3>The Lemonade Love</h3>
//                 <p>Slot: Morning</p>
//                 <p>Packaging Type: Reusable</p>
//                 <div className="cart-ip">
//                   <button onClick={handleDecrement}>-</button>
//                   <input type="number" value={quantity} readOnly />
//                   <button onClick={handleIncrement}>+</button>
//                 </div>
//               </div>
//               <img src={close} />
//             </div>
//             <br />
//             <br />
//             <br />
//             <br />
//             <br />
//             <br />

//             <div className="cart-total">
//               <p>Subtotal (2)</p>
//               <h3>Rs 200.00</h3>
//             </div>
//             <br />

//             <div className="cart-total">
//               <p>Tax</p>
//               <h3>Rs 21.60</h3>
//             </div>
//             <br />

//             <div className="cart-total">
//               <p>Shipping</p>
//               <h3>Free</h3>
//             </div>
//             <br />

//             <div className="cart-total">
//               <p>Total Orders:</p>
//               <h3>Rs 221.60</h3>
//             </div>
//           </div>
//         </div>
