import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../utils/data";
import CustomCalendar from "./CustomCalendar"; // Import the CustomCalendar component
import "react-calendar/dist/Calendar.css"; // Import the calendar CSS
import load from "../../Assets/newlogo.png";
const CalendarProduct = () => {
  const [subscription, setSubscription] = useState([]);
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [calendarDates, setCalendarDates] = useState({
    deliveryDates: [],
    pauseDates: [],
    upcomingDeliveryDates: [],
  });
  const [loading, setLoading] = useState(true); // Add loading state

  const [selectedDates, setSelectedDates] = useState([]);
  const [selectMultiple, setSelectMultiple] = useState(false);
  const [selectableDates, setSelectableDates] = useState([]);
  const [currentSubscriptionId, setCurrentSubscriptionId] = useState(null);
  const [action, setAction] = useState("");

  const generateUpcomingDeliveryDates = (pauseDates, remain, startDate) => {
    let upcomingDeliveryDates = [];
    let today = new Date();
    // console.log(remain);
    while (upcomingDeliveryDates.length < remain) {
      today.setDate(today.getDate() + 1); // Move to the next day

      // Check if the day is Sunday
      if (today.getDay() === 0) {
        continue;
      }
      // console.log(today, new Date(startDate));
      if (today < new Date(startDate)) {
        continue;
      }

      // Check if the day is in pauseDates
      let isPauseDate = pauseDates.some((pauseDate) => {
        let date = new Date(pauseDate);
        return date.toDateString() === today.toDateString();
      });

      if (isPauseDate) {
        continue;
      }

      // Add the date to upcomingDeliveryDates
      upcomingDeliveryDates.push(new Date(today));
    }
    console.log(upcomingDeliveryDates);
    return upcomingDeliveryDates;
  };

  const processSubscriptionData = (data) => {
    return data.map((item) => {
      const pauseDates = item.pauseDates.map((date) =>
        new Date(date).toDateString()
      );
      const deliveryDates = item.deliveryDates.map((date) =>
        new Date(date).toDateString()
      );
      const remain = item.remain;

      const upcomingDeliveryDates = generateUpcomingDeliveryDates(
        pauseDates,
        remain,
        item?.startDate
      ).map((date) => date.toDateString());

      return {
        ...item,
        id: item._id,
        pauseDates: pauseDates,
        deliveryDates: deliveryDates,
        upcomingDeliveryDates: upcomingDeliveryDates,
      };
    });
  };

  const getData = async () => {
    try {
      const res = await axios.get(`${baseUrl}/subscription`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (res?.status === 200) {
        const processedData = processSubscriptionData(res?.data?.data);
        setSubscription(processedData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Set loading to false once data is fetched
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const toggleCalendar = (sub) => {
    setCalendarVisible(!calendarVisible);
    setCalendarDates(sub);
    setCurrentSubscriptionId(sub.id);
    setSelectableDates(sub.pauseDates);
    setSelectMultiple(false);
    setAction("");
    setSelectedDates([]);
  };

  const closeCalendar = () => {
    setCalendarVisible(false);
    setSelectedDates([]);
    setAction("");
  };

  const updateSubscription = async (id, dates, action) => {
    console.log(dates);
    // const formattedDates = dates.map((date) => {
    //   const d = new Date(date);
    //   return d.toISOString().split("T")[0];
    // });

    const payload = {
      isActive: action === "pause",
      pauseDates: dates,
    };

    try {
      const res = await axios.put(
        `${baseUrl}/subscription/updateorder/${id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (res?.status === 200) {
        getData(); // Refresh data after update
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirm = () => {
    if (selectedDates.length > 0) {
      updateSubscription(currentSubscriptionId, selectedDates, action);
      closeCalendar();
    }
  };
  const handleDateSelect = (dates) => {
    const selectedDate = new Date(dates[0]);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const dateString = new Date(dates[0]).toDateString();
    if (selectedDate < today) {
      alert("Invalid date selected");
      return;
    }
    if (calendarDates.pauseDates.includes(dateString)) {
      setAction("resume");
      setSelectedDates(dates);
      setSelectMultiple(true);
      setSelectableDates(calendarDates.pauseDates);
    } else if (calendarDates.upcomingDeliveryDates.includes(dateString)) {
      setAction("pause");
      setSelectedDates(dates);
      setSelectMultiple(true);
      setSelectableDates(calendarDates.upcomingDeliveryDates);
    } else {
      alert("Invalid date selected");
    }
  };
  if (loading) {
    return (
      <div className="loader-div">
        <br />
        <br />
        <img src={load} alt="Logo" class="loader-logo" />
        <div class="spinner"></div>
        <br />
        <br />
      </div>
    );
  }
  return (
    <div
      className="dashboard-div"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <br />
      <br />
      <div>
        {subscription.map((sub, index) => (
          <div
            key={index}
            className="product-container product-container2"
            style={{
              marginBottom: "30px",

              padding: "30px",
            }}
          >
            <div className="product-container-left" style={{ width: "400px" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <h2>{sub?.product?.name}</h2>
                <br />

                {sub?.isTrial && (
                  <button
                    style={{
                      padding: "10px 20px",
                      backgroundColor: "rgba(255, 207, 6, 1)",
                      color: "white",
                      border: "none",
                      cursor: "pointer",
                      borderRadius: "10px",
                      fontWeight: "700",
                    }}
                  >
                    Trial
                  </button>
                )}
              </div>
              <img
                src={sub?.product?.imageUrl || sub?.product?.images?.image1}
                alt={sub?.product?.name}
                style={{ width: "100px" }}
              />
            </div>
            <div
              className="product-container-right"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                background:
                  "linear-gradient(rgba(255, 146, 0, 0.81), rgba(255, 193, 0, 0.77))",
              }}
            >
              <button
                onClick={() => toggleCalendar(sub)}
                style={{
                  padding: "10px 20px",
                  backgroundColor: "rgba(239, 65, 54, 1)",
                  color: "white",
                  border: "none",
                  cursor: "pointer",
                  borderRadius: "10px",
                  fontWeight: "700",
                }}
              >
                Open Calendar
              </button>
              {calendarVisible && currentSubscriptionId === sub.id && (
                <div
                  style={{
                    padding: "20px",
                  }}
                >
                  <CustomCalendar
                    dates={calendarDates}
                    onClose={closeCalendar}
                    onSelectDates={handleDateSelect}
                    selectMultiple={selectMultiple}
                    selectableDates={selectableDates}
                    action={action}
                    setAction={setAction}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "20px",
                    }}
                  >
                    {action === "pause" && (
                      <button
                        onClick={handleConfirm}
                        disabled={selectedDates.length === 0}
                        className="Pause"
                      >
                        Pause
                      </button>
                    )}
                    {action === "resume" && (
                      <button
                        onClick={handleConfirm}
                        disabled={selectedDates.length === 0}
                        className="Resume"
                      >
                        Resume
                      </button>
                    )}
                  </div>
                  {/* <br /><br /> */}
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "10px",
                      }}
                      className="nameing"
                    >
                      <div
                        style={{
                          width: "10px",
                          height: "10px",
                          borderRadius: "50%",
                          backgroundColor: "green",
                          marginRight: "10px",
                        }}
                      ></div>
                      <span>Delivered</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "10px",
                      }}
                      className="nameing"
                    >
                      <div
                        style={{
                          width: "10px",
                          height: "10px",
                          borderRadius: "50%",
                          backgroundColor: "red",
                          marginRight: "10px",
                        }}
                      ></div>
                      <span>Pause</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "10px",
                      }}
                      className="nameing"
                    >
                      <div
                        style={{
                          width: "10px",
                          height: "10px",
                          borderRadius: "50%",
                          backgroundColor: "yellow",
                          marginRight: "10px",
                        }}
                      ></div>
                      <span>Upcoming</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
    //     <>
    //       <div className="push-resume">
    // <button className="Pause">Pause</button>
    // <button className="Resume">Resume</button>

    //       </div>
    //     </>
  );
};

export default CalendarProduct;
