import React, { useEffect, useState } from "react";
import sun from '../Assets/sun.png'
import free from '../Assets/Free Fast Food Delivery Bike PNG, SVG Icon.jpeg'

import Navbar from "../Components/Navbar";
import SingleProductDetail from "../Components/SingleProductDetail";
import img1 from "../Assets/image 202.svg";
import Products from "../Components/Products";
import Footer from "../Components/Footer";
import SubProduct from "../Components/SubProduct";

function ProductDetails() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [activeTab, setActiveTab] = useState(1);

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <>
      <Navbar />
      {/* <div className="pd-banner"></div> */}

      <SingleProductDetail />
      <br/>
      <br/>
      <div className="fruitpunch">
      <div className="fruitpunch-right">
          {/* <img src={pnuch2}/> */}
          <h1>
          Our  <span>Subscription</span> model
          </h1>
          <br />
          <br />
          <br />
          <br />
          <div className="punch-cards">
            <div className="punch-card">
              <div className="punch-icon">
<p>26</p>
              </div>

              {/* <h3>Organic Sweetener</h3> */}
              <p>A total of 26 products will be delivered </p>
            </div>
            <div className="punch-card">
            <div className="punch-icon">
<p>250 ml</p>


              </div>

              {/* <h3>Natural Goods</h3> */}
              <p>Serving quantity of each juice is 250ml  (for fruit bowl is 400 - 450g)
</p>
            </div>
            <div className="punch-card">
            <div className="punch-icon">
<img className="free" src={sun}/>

              </div>

              {/* <h3>Fresh Organic Fruits</h3> */}
              <p>We deliver our fresh products in the slots 7-9 AM & 3-5 PM</p>
            </div>
            <div className="punch-card">
            <div className="punch-icon">
<p>Mon-Sat</p>

              </div>

              {/* <h3>Completely Natural</h3> */}
              <p>Products are delivered 6 days a week (Mon - Sat)
</p>
            </div>
            <div className="punch-card">
            <div className="punch-icon">
<img className="free" src={free}/>

              </div>

              {/* <h3>Higher in Yum</h3> */}
              <p>We provide free delivery doorstep or office</p>
            </div>
            <div className="punch-card">
            <div className="punch-icon">

            <i class="bi bi-pause-circle-fill"></i>
<i class="bi bi-play-fill"></i>
              </div>

              {/* <h3>Bottled in Glass</h3> */}
              <p>Subscribers can pause & resume the subscription at their convenience
</p>
            </div>
          </div>
        </div>
        <div className="fruitpunch-left">
          {/* <img src={pnuch} /> */}
          <h1>
          Our   <span>Trial Pack</span>
          </h1>
          <div className="trial-pack-cards">
          <div className="trial-pack-card">
<h3>1 Time</h3>
          <p>Once per product,<br/> per user</p>

</div>
   <div className="trial-pack-card">
<h3>6</h3>
          <p>6 products in the Trial pack<br/> with all the features</p>

</div>
          </div>
        </div>
       
      </div>
      <div className="products-div">
      <br/>
      <br/>
      <br/>

        <h1>
          What You Get In <br/>This <span> Subscription  </span>
          {/* Recommended <span>Product</span>{" "} */}
        </h1>
        <br/>
        <p style={{textAlign:'center' , color:'black' , fontWeight:'400' }}>We choose 6 different fruits in a week for you</p>
      </div>
      <SubProduct />

      {/* <div className="Description">
        <div className="tab-container">
          <div className="tabs">
            <button
              className={activeTab === 1 ? "tab-btn active" : "tab-btn"}
              onClick={() => handleTabChange(1)}
            >
              Description
            </button>
            <button
              className={activeTab === 2 ? "tab-btn active" : "tab-btn"}
              onClick={() => handleTabChange(2)}
            >
              Shipping Information
            </button>
            <button
              className={activeTab === 3 ? "tab-btn active" : "tab-btn"}
              onClick={() => handleTabChange(3)}
            >
              Reviews
            </button>
          </div>
          <div className="tab-content">
            {activeTab === 1 && (
              <>
                <div className="tab-div">
                  <div className="tab-div-left">
                    <h1>START YOUR HEALTHY DAY WITH JUICE.</h1>
                    <br />
                    <p>
                      Our products come from hundreds of farms, and when they
                      were gathered, the best fruits from each farm were
                      selected. The chosen people are whittled down to nothing.
                      The juices are made by combining these pulps with our
                      top-secret components. It has no chemical coloring or
                      preservatives of any kind. Our fruit juices are loaded
                      with vitamins and minerals. The ideal way to take them is
                      through freshly squeezed juices, and the juices of
                      vegetables and fruits with the highest antioxidant
                      concentrations provide the best defense against damage
                      brought on by unstable chemicals that naturally exist in
                      your body.
                    </p>
                  </div>
                  <div className="tab-div-right">
                    <img src={img1} />
                  </div>
                </div>
                <br />
                <br />
                <br />
                <br />
                <div className="tab-div">
                  <div className="tab-div-right">
                    <img src={img1} />
                  </div>
                  <div className="tab-div-left">
                    <h1>START YOUR HEALTHY DAY WITH JUICE.</h1>
                    <br />
                    <p>
                      Our products come from hundreds of farms, and when they
                      were gathered, the best fruits from each farm were
                      selected. The chosen people are whittled down to nothing.
                      The juices are made by combining these pulps with our
                      top-secret components. It has no chemical coloring or
                      preservatives of any kind. Our fruit juices are loaded
                      with vitamins and minerals. The ideal way to take them is
                      through freshly squeezed juices, and the juices of
                      vegetables and fruits with the highest antioxidant
                      concentrations provide the best defense against damage
                      brought on by unstable chemicals that naturally exist in
                      your body.
                    </p>
                  </div>
                </div>
              </>
            )}
            {activeTab === 2 && <></>}
          </div>
        </div>
      </div> */}

   
      <Footer />
    </>
  );
}

export default ProductDetails;
