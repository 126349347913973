import React, { useState, useEffect, useCallback, useRef } from "react";
import place from "../Assets/direction-location-map-pin-place-svgrepo-com 2.png";
import newbanner from "../Assets/newbanner.png";
import { toast } from "react-toastify";
import { baseUrl } from "../utils/data";
import axios from "axios";
import { GoogleMap, LoadScript, Marker, Circle } from "@react-google-maps/api";
import { Button } from "reactstrap";
import SmallCom from "./SmallCom";

const baseLocation = { lat: 17.45287804553729, lng: 78.38900685310364 };
const radiusInMeters = 8000;

const StepThree = ({ step, handleNext }) => {
  const [houseNumber, setHouseNumber] = useState("");
  const [newAddress, setNewAddress] = useState("");
  const [newPincode, setNewPincode] = useState("");
  const [newLocation, setNewLocation] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const mapRef = useRef(null);


  useEffect(() => {
    const storedProduct = JSON.parse(localStorage.getItem("selectedProduct"));
    if (storedProduct) {
      setSelectedProduct(storedProduct);
    }
  }, []);

  const handleAddAddress = async () => {
    if (!newLocation?.lat || !newLocation?.lng) {
      return toast.error("Please select Location on Map");
    }

    if (!houseNumber) return toast.error("Please Enter House Number.");
    if (!newAddress) return toast.error("Please Enter Address.");
    if (!newPincode) return toast.error("Please Enter Pincode.");

    let addup = houseNumber.trim() + " " + newAddress.trim();
    try {
      await axios.put(
        `${baseUrl}/user/address/add`,
        { address: addup, pincode: newPincode, location: newLocation },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        }
      );
      toast.success("Address added successfully");
      //   fetchAddresses();
      setHouseNumber("");
      setNewAddress("");
      setNewPincode("");
      setNewLocation(null);
      handleNext()
    } catch (error) {
      console.error("Error adding address:", error);
      toast.error("Failed to add address");
    }
  };

  const handleMapClick = useCallback((event) => {
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();

    if (window.google && window.google.maps && window.google.maps.geometry) {
      const distance =
        window.google.maps.geometry.spherical.computeDistanceBetween(
          new window.google.maps.LatLng(baseLocation.lat, baseLocation.lng),
          new window.google.maps.LatLng(lat, lng)
        );
      //   console.log(distance);
      if (distance <= radiusInMeters) {
        setNewLocation({ lat, lng });

        // Fetch address and pincode using Geocoding API
        fetchAddressFromLatLng(lat, lng);
      } else {
        toast.error("Selected location is outside the 13 km radius");
      }
    }
  }, []);

  const fetchAddressFromLatLng = async (lat, lng) => {
    const apiKey = "AIzaSyD8qT43Pj2CBQnepwSN3jahcczBeWsyM2k";
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`
    );
    const data = await response.json();
    if (data.status === "OK") {
      const addressComponents = data.results[0].address_components;
      const formattedAddress = data.results[0].formatted_address;
      const pincode = addressComponents.find((component) =>
        component.types.includes("postal_code")
      ).long_name;

      setNewAddress(formattedAddress);
      setNewPincode(pincode);
    } else {
      toast.error("Failed to fetch address details");
    }
  };

  const handleGeolocation = () => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((permissionStatus) => {
          if (permissionStatus.state === "denied") {
            toast.error("Please Allow Loaction First.");
          } else {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                console.log(position);
                const currentLocation = {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude
                };
                const distance =
                  window.google.maps.geometry.spherical.computeDistanceBetween(
                    new window.google.maps.LatLng(
                      baseLocation.lat,
                      baseLocation.lng
                    ),
                    new window.google.maps.LatLng(
                      currentLocation.lat,
                      currentLocation.lng
                    )
                  );

                if (distance <= radiusInMeters) {
                  setNewLocation(currentLocation);
                  mapRef.current.panTo(currentLocation);
                  fetchAddressFromLatLng(
                    currentLocation.lat,
                    currentLocation.lng
                  );
                } else {
                  toast.error("Current location is outside the 13 km radius");
                }
              },
              (error) => {
                if (error.code === error.PERMISSION_DENIED) {
                  toast.error("Please Allow Loaction First.");
                } else {
                  toast.error("Unable to retrieve your location.");
                }
              }
            );
          }
        });
    } else {
      toast.error("Geolocation is not supported by this browser.");
    }
  };

  return (
    <div className="mission-clip4">
      {/* <div>
        <h2>{selectedProduct.name}</h2>
        <div className="slot-banner">
          <img src={newbanner} />
          <div className="slot-banner-up">
            <p>Trail / 1m</p>
            <h3>Rs 650 / 2599</h3>
          </div>
        </div>
      </div> */}
      <SmallCom />
      <br />
      <div>
        <div className="mission-slot-day-eveing">
          <div className="address-details">
            <div>
              <h4>Delivery Address</h4>
              <p>Block P Phase 1 Johar Town, Lahore</p>
            </div>
          </div>
          <br />
          <div className="map">
            {/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30765978.00238801!2d61.00083698256397!3d19.729113061269324!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30635ff06b92b791%3A0xd78c4fa1854213a6!2sIndia!5e0!3m2!1sen!2sin!4v1729618699453!5m2!1sen!2sin"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"></iframe> */}
            <LoadScript
              googleMapsApiKey="AIzaSyD8qT43Pj2CBQnepwSN3jahcczBeWsyM2k"
              libraries={["geometry"]}>
              <GoogleMap
                mapContainerStyle={{ width: "100%", height: "250px" ,borderRadius:'16px' }}
                center={baseLocation}
                zoom={12}
                onClick={handleMapClick}
                onLoad={(map) => (mapRef.current = map)}
                options={{
                  mapTypeControl: false,
                  zoomControl: true,
                  streetViewControl: true,
                  fullscreenControl: true
                }}>
                <Marker
                  position={baseLocation}
                  icon={{
                    url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png" // Green marker for base location
                  }}
                />
                {newLocation && (
                  <Marker
                    position={newLocation}
                    icon={{
                      url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png" // Blue marker for new location
                    }}
                  />
                )}
                <Circle
                  center={baseLocation}
                  radius={0}
                  options={{
                    fillColor: "green",
                    fillOpacity: 0.2,
                    strokeColor: "green",
                    strokeOpacity: 0.5
                  }}
                />
              </GoogleMap>
            </LoadScript>
          </div>
          <br />
          {/* <div className="pin-location">
            <div>
              <img src={place} />
            </div>
            <div>
              <h3>Johar Town</h3>
              <p>31244, King Street, Toronto, ON</p>
            </div>
          </div> */}
          <div>
            <Button
              onClick={handleGeolocation}
              color="primary"
              variant="contained">
              Tag My Location
            </Button>
          </div>

          <br />

          <div className="mission-numbver">
            <label>House/Flat/Block No.</label>
            <input
              style={{ marginTop: "15px" }}
              placeholder="Enter House/Flat/Block No."
              value={houseNumber}
              onChange={(e) => setHouseNumber(e.target.value)}
            />
          </div>

          <br />

          <div className="mission-numbver">
            <label>Apartment/Road/Area.</label>
            <input
              style={{ marginTop: "15px" }}
              placeholder="Enter Apartment/Road/Area."
              value={newAddress}
              onChange={(e) => setNewAddress(e.target.value)}
            />
          </div>
          <br />

          <div className="mission-numbver">
            <label>Pincode</label>
            <input
              style={{ marginTop: "15px" }}
              placeholder="Enter Pincode"
              value={newPincode}
              onChange={(e) => setNewPincode(e.target.value)}
            />
          </div>
          <br />

          <div className="mission-slot-day-eveing">
            <h3 style={{ textAlign: "left" }}>Save as</h3>
            <br />
            <div class="radio-buttons radio-buttons2">
              <input
                type="radio"
                id="option1"
                name="radio-group"
                class="radio-input2"
              />
              <label for="option1" class="radio-label2">
                Home
              </label>

              <input
                type="radio"
                id="option2"
                name="radio-group"
                class="radio-input2"
              />
              <label for="option2" class="radio-label2">
                Office
              </label>

              <input
                type="radio"
                id="option3"
                name="radio-group"
                class="radio-input2"
              />
              <label for="option3" class="radio-label2">
                Other
              </label>
            </div>
          </div>
        </div>
        <br />
        <br />

        <button className="missionloginbtn" onClick={handleAddAddress}>
          Save Address
        </button>
      </div>
    </div>
  );
};

export default StepThree;
