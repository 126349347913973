import React from 'react'
import { Link } from 'react-router-dom'

function Banner() {
  return (
    <div className='banner'>
    <h1><span>Nutrition</span> and <span>Hydration</span><br/> with <span>Innovation</span> </h1>
   <br/>
    
    <p>Through continuous innovation, we strive to enhance every aspect of our products, ensuring the delivery of the finest and most delicious juices to your door. 
</p>
   <br/>
   <Link to='/newproduct'>

    <button>Subscribe Now</button>
   </Link>
    </div>
  )
}

export default Banner