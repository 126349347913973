import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import whatsapp from "../Assets/WhatsApp_icon.png.webp";
import logo from "../Assets/newlogo.png";
import icon1 from "../Assets/Group 1000001787.svg";
import icon2 from "../Assets/Group 1000001788.svg";
import icon3 from "../Assets/Group 1000001789.svg";
import icon4 from "../Assets/Group 1000001790.svg";
import { baseUrl } from "../utils/data";
import { toast } from "react-toastify";
import axios from "axios";
import { useCart } from "../cartContext/cartContext";

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [login, setLogin] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [sliderOpen, setSliderOpen] = useState(false);
  const {cartLength, setCartLength} = useCart()

  const fetchCartData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/newcart/getcartbyuser`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setCartItems(response?.data?.data?.items);
      setCartLength(response?.data?.data?.items?.length);
    } catch (error) {
      console.error("Error fetching cart data:", error);
      if (error?.response?.data?.message === "User not authorized") {
        // toast.error("Please login again.");
        // navigate("/Login");
      }
    }
  };


  const toggleSlider = () => {
    setSliderOpen(!sliderOpen);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setLogin(true);
    }
    fetchCartData();
  }, []);
  const getActiveClass = (path) => {
    return location.pathname === path ? "active" : "";
  };

  return (
    <>
      <nav>
        <Link
          className="whatsapp"
          to="https://api.whatsapp.com/send?phone=918977037763"
        >
          <img src={whatsapp} />
        </Link>
        <Link to="/">
          <img className="logo" src={logo} alt="Logo" />
        </Link>
        <ul>
          <Link className={`nan ${getActiveClass("/")}`} to="/">
            <li>Home</li>
          </Link>
          <Link className={`nan ${getActiveClass("/about")}`} to="/about">
            <li>About Us</li>
          </Link>
          <Link
            className={`nan ${getActiveClass("/newproduct")}`}
            to="/newproduct"
          >
            <li>Products</li>
          </Link>
          <Link className={`nan ${getActiveClass("/Plans")}`} to="/Plans">
            <li>Subscription</li>
          </Link>
          <Link
            className={`nan ${getActiveClass("/Dashboard")}`}
            to="/Dashboard"
          >
            <li>Dashboard</li>
          </Link>
          <Link className={`nan ${getActiveClass("/Contact")}`} to="/Contact">
            <li>Contact Us</li>
          </Link>
          {/* <Link to="/CheckOut">
          <li>
            <img src={icon1} alt="Checkout" />
          </li>
        </Link> */}
          {/* <Link to="/Shipping">
          <li>
            <img src={icon2} alt="Shipping" />
          </li>
        </Link> */}
          {/* <Link to="/CheckOut">
          <li>
          <i class="bi bi-cart3"></i><p>{cartItems.length||0}</p>
          </li>
        </Link> */}
          <Link
            to="/CheckOut"
            style={{ position: "relative", display: "inline-block" }}
          >
            <li style={{ listStyle: "none", position: "relative" }}>
              <i
                className="bi bi-cart3"
                style={{ fontSize: "24px", position: "relative" }}
              ></i>
              <p
                style={{
                  position: "absolute",
                  top: "-15px",
                  right: "-15px",
                  background: "red",
                  color: "white",
                  borderRadius: "50%",
                  padding: "4px 8px",
                  fontSize: "12px",
                  margin: 0,
                }}
              >
                {cartLength || 0}
              </p>
            </li>
          </Link>
          {login ? (
            <Link to="/Profile">
              <li>
                <i class="bi bi-person-fill"></i>
              </li>
            </Link>
          ) : (
            <Link to="/Login">
              <li>
                <i class="bi bi-person-fill"></i>
              </li>
            </Link>
          )}
        </ul>

        <div className="menu-btns">
          <Link className="none" to="/CheckOut"  style={{ position: "relative", display: "inline-block" }}>
            <i class="bi bi-basket2-fill"></i>
            <p
                style={{
                  position: "absolute",
                  top: "-15px",
                  right: "-15px",
                  background: "red",
                  color: "white",
                  borderRadius: "50%",
                  padding: "4px 8px",
                  fontSize: "12px",
                  margin: 0,
                }}
              >
                {cartLength || 0}
              </p>
          </Link>
          <i className="bi bi-list" onClick={toggleSlider}></i>
        </div>
        {/* Slider */}
        <div className={`slider ${sliderOpen ? "slider-open" : ""}`}>
          <a
            href="#"
            style={{ backgroundColor: "white" }}
            className="closebtn"
            onClick={toggleSlider}
          >
            &times;
          </a>
          <Link className={getActiveClass("/")} to="/">
            Home
          </Link>
          <Link className={getActiveClass("/about")} to="/about">
            About Us
          </Link>
          <Link className={getActiveClass("/newproduct")} to="/newproduct">
            Products
          </Link>
          <Link className={getActiveClass("/Plans")} to="/Plans">
            Subscription
          </Link>
          <Link className={getActiveClass("/Dashboard")} to="/Dashboard">
            Dashboard
          </Link>
          <Link className={getActiveClass("/Contact")} to="/Contact">
            Contact Us
          </Link>
          {/* <Link className={getActiveClass("/CheckOut")} to="/CheckOut">Cart</Link>
        <Link className={getActiveClass("/Shipping")} to="/Shipping">Checkout</Link> */}
          {login ? (
            <Link className={getActiveClass("/Profile")} to="/Profile">
              Profile
            </Link>
          ) : (
            <Link className={getActiveClass("/Login")} to="/Login">
              Login
            </Link>
          )}
        </div>
      </nav>
    </>
  );
}

export default Navbar;
