import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../utils/data";
import { useNavigate, useParams } from "react-router-dom";
import load from "../Assets/newlogo.png";
function Products() {
  //   const { id } = useParams();
  const [allSub, setAllSub] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const getData = async () => {
    try {
      const res = await axios.get(`${baseUrl}/subproduct/random`);
      if (res?.status === 200) {
        setAllSub(res?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching data", error);
    } finally {
      setLoading(false); // Set loading to false once data is fetched
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const truncateDescription = (description) => {
    return description.length > 20
      ? description.slice(0, 20) + "..."
      : description;
  };
  if (loading) {
    return (
      <div className="loader-div">
        <img src={load} alt="Logo" class="loader-logo" />
        <div class="spinner"></div>
      </div>
    );
  }
  return (
    <>
      <div className="products-div">
        <div className="product-cards">
          {allSub.map((product, index) => (
            <div key={product._id} className="product-card product-card2">
              <img src={product.images?.image1} alt={product.name} />
              <br />

              <p>{product.name}</p>
              {/* <p>{truncateDescription(product.description)}</p> */}
              <button
                onClick={() => navigate(`/SubProductDetails/${product?._id}`)}
              >
                View More
              </button>
            </div>
          ))}
        </div>
        <br />
        <br />
        <br />
        {/* <button className="viewallbtn">VIEW ALL</button> */}
        <br />
        <br />
      </div>
    </>
  );
}

export default Products;
