import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../utils/data";
import smalimg from "../../Assets/juice 2.svg";
import load from "../../Assets/newlogo.png";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DeliveryProductModal from "./DeliveryProductModal";
const DeliveryProduct = () => {
  const navigate = useNavigate()
  const [subscription, setSubscription] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [remianCount, setRemainCount] = useState(0)

  const getData = async () => {
    try {
      const res = await axios.get(`${baseUrl}/subscription`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (res?.status === 200) {
        setSubscription(res?.data?.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (loading) {
    return (
      <div className="loader-div">
        {/* <br />
        <br />
        <div className="loader"></div>
        <br />
        <br /> */}
        <img src={load} alt="Logo" class="loader-logo" />
        <div class="spinner"></div>
      </div>
    );
  }

  const handleCheckout = async(subscriptionId) => {
    if(!subscriptionId){
      return toast.error("Id not found.")
    }
    const userData = JSON.parse(localStorage.getItem("userData")) || null;
    if (userData) {
      const { name, mobile } = userData;
      if (!name || !mobile) {
        toast.error("Please Update Your Profile First.");
        navigate("/Profile");
      } else {
        setLoading(true)
        try {
          const res = await axios.post(
            `${baseUrl}/subscription/renewplan`,
            { subscriptionId: subscriptionId },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          // console.log(res);
          if (res?.status == 200) {
            const {batchId:orderId, orderAmount, packingAmount} = res?.data?.data
            // console.log(res);
            // return
            const result = await axios.post(`${baseUrl}/phonepe/phoneperequest`, {
              amount: Number(orderAmount)+Number(packingAmount),
              merchantUserId: orderId,
            });
            if (result?.status == 200) {
              window.location.href = result?.data;
            }
            // toast.success("Subscription Buy Successfully.");
            // navigate("/");
          }
        } catch (error) {
          toast.error("Buy Subscription Failed.");
        }
        setLoading(false)
    
        // navigate("/Shipping");
      }
    } else {
      toast.error("Please Login First.");
      navigate("/Login");
    }
  };

  const handleRenewClick = (subscription) => {
    setSelectedSubscription(subscription);
    setModalShow(true);
  };

  return (
    <div
      className="dashboard-div"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div>
        {subscription.map((sub, index) => {
          const totalDays = sub.isTrial ? 6 : 26;
          const completedDays = sub.deliveryProduct.filter(
            (product) => product.productImg?.image1
          ).length;
          const remainingDays = totalDays - completedDays;

          return (
            <div
              key={index}
              className="product-container product-container2"
              style={{ marginBottom: "30px" }}
            >
              <div className="product-container102">
                <div className="product-container101">
                  <div className="product-container-left">
                    <h2>{sub?.product?.name}</h2>
                    <img
                      src={
                        sub?.product?.imageUrl || sub?.product?.images?.image1
                      }
                      alt={sub?.product?.name}
                      style={{ width: "100px" }}
                    />
                  </div>
                  <div className="product-container-right">
                    {[...Array(totalDays)].map((_, i) => (
                      <div key={i} className="cell">
                        {sub?.deliveryProduct[i]?.productImg?.image1 && (
                          <div
                            style={{
                              backgroundColor: "rgba(255, 207, 6, 1)",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={
                                sub?.deliveryProduct[i]?.productImg?.image1 ||
                                ""
                              }
                              alt={sub?.product?.name}
                              className="cell-image"
                              style={{ width: "30px" }}
                            />
                            <p>{sub?.deliveryProduct[i]?.productName}</p>
                          </div>
                        )}
                        <div
                          className="small"
                          style={{
                            backgroundColor: sub?.deliveryProduct[i]?.productImg
                              ?.image1
                              ? "rgba(255, 207, 6, 1)"
                              : "initial",
                          }}
                        >
                          <span style={{ color: "#EF4136" }}>Day {i + 1}</span>
                        </div>
                      </div>
                    ))}
                    <button className="renew" disabled={sub.isRenew} onClick={()=>handleRenewClick(sub)}>Renew</button>
                    {/* <button className="renew" disabled={sub.isTrial||sub.isRenew} onClick={()=>handleCheckout(sub?._id)}>Renew</button> */}
                  </div>
                </div>
                <div className="dayCount">
                  <div className="dayCount-complete">
                    <div className="complete">{completedDays}</div>
                    <p>Days Completed</p>
                  </div>
                  <div className="dayCount-remaning">
                    <div className="remaning">{remainingDays}</div>
                    <p>Products Remaining</p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <DeliveryProductModal
        show={modalShow}
        handleClose={() => setModalShow(false)}
        subscription={selectedSubscription}
      />

    </div>
  );
};

export default DeliveryProduct;
