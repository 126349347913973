import React, { useEffect, useState } from "react";
import product from "../../Assets/image 196.svg";
import axios from "axios";
import { baseUrl } from "../../utils/data";
import load from '../../Assets/newlogo.png'
const AllProduct = () => {
  const [allProduct, setAllProduct] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const getData = async () => {
    try {
      const res = await axios.get(`${baseUrl}/subscription/active`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (res?.status == 200) {
        setAllProduct(res?.data?.data);
      }
    } catch (error) {}
    finally {
      setLoading(false); // Set loading to false once data is fetched
    }
  };
  useEffect(() => {
    getData();
  }, []);
  console.log(allProduct);
  if (loading) {
    return (
      <div className="loader-div">
     <img src={load} alt="Logo" class="loader-logo"/>
     <div class="spinner"></div>

      </div>
    );
  }
  return (
    <div className="Dashboard-div">
      <div className="Dashboard-div-left">
        <h1>Product Of the Day</h1>
        {allProduct &&
          allProduct?.length > 0 &&
          allProduct.map((product, index) => (
            <div>

            <div key={index} className="dashboard-product">
              <img
                src={
                  product?.todayProduct?.images?.image1 ||
                  product?.todayProduct?.images?.image2 ||
                  product?.todayProduct?.images?.image3 ||
                  product
                }
                alt={product?.name}
              />
              <br/>
              <p>{product?.todayProduct?.name}</p>
              {/* <p>{product?.todayProduct?.description?.substring(0, 20)}</p> */}
            </div>
            <div className="Dashboard-div-right">
        <h1>Nutrition Facts</h1>
        
            <div className="nut-table">
                <br /><br />
              <div className="Nutrition-table">
                <p>Nutrient</p>
                <p>Content</p>
              </div>
              <br />
              <div className="Nutrition-table">
                <p>Fiber</p>
                <p>4.8g</p>
              </div>
              <br />
              <div className="Nutrition-table">
                <p>Fiber</p>
                <p>4.8g</p>
              </div>
              <br />
              <div className="Nutrition-table">
                <p>Fiber</p>
                <p>4.8g</p>
              </div>
              <br />
              <div className="Nutrition-table">
                <p>Fiber</p>
                <p>4.8g</p>
              </div>
              <br />
         
            </div>
        
      </div>

            </div>

          ))}
      </div>
      
      <br /><br /><br />
      <br /><br /><br />
    </div>
  );
};

export default AllProduct;
