import React from "react";
import Navbar from "../Components/Navbar";
import Banner from "../Components/Banner";
import banner2 from "../Assets/if we do it, we do it right.png";
import leaf from "../Assets/leaf.svg";
import ProductCategories from "../Components/ProductCategories";
import Products from "../Components/Products";

import pnuch from "../Assets/Container1.svg";
import pnuch2 from "../Assets/Margin2.svg";
import icon1 from "../Assets/fruit icon (1).svg";
import icon2 from "../Assets/fruit icon (2).svg";
import icon3 from "../Assets/fruit icon (3).svg";
import icon4 from "../Assets/fruit icon (4).svg";
import icon5 from "../Assets/fruit icon (5).svg";
import icon6 from "../Assets/fruit icon (6).svg";
import Testimonials from "../Components/Testimonials";
import Blogs from "../Components/Blogs";
import Footer from "../Components/Footer";
import Banner2 from "../Components/Banner2";
import free from "../Assets/Free Fast Food Delivery Bike PNG, SVG Icon.jpeg";
import OurProduct from "./HomePage/OurProduct";
import sun from "../Assets/sun.png";
function Home() {
  return (
    <>
      <Navbar />
      <Banner2 />
      <br />
      <br />
      <br />
      <br />
      <div className="fruitpunch">
        <div className="fruitpunch-right">
          {/* <img src={pnuch2}/> */}
          <h1>
            Our <span>Subscription</span> model
          </h1>
          <br />
          <br />
          <br />
          <br />
          <div className="punch-cards">
            <div className="punch-card">
              <div className="punch-icon">
                <p>26</p>
              </div>

              {/* <h3>Organic Sweetener</h3> */}
              <p>A total of 26 products will be delivered </p>
            </div>
            <div className="punch-card">
              <div className="punch-icon">
                <p>250 ml</p>
              </div>

              {/* <h3>Natural Goods</h3> */}
              <p>
                Serving quantity of each juice is 250ml (for fruit bowl is 400 -
                450g)
              </p>
            </div>
            <div className="punch-card">
              <div className="punch-icon">
                <img className="free" src={sun} />
              </div>

              {/* <h3>Fresh Organic Fruits</h3> */}
              <p>We deliver our fresh products in the slots 7-9 AM & 3-5 PM</p>
            </div>
            <div className="punch-card">
              <div className="punch-icon">
                <p>Mon-Sat</p>
              </div>

              {/* <h3>Completely Natural</h3> */}
              <p>Products are delivered 6 days a week (Mon - Sat)</p>
            </div>
            <div className="punch-card">
              <div className="punch-icon">
                <img className="free" src={free} />
              </div>

              {/* <h3>Higher in Yum</h3> */}
              <p>We provide free delivery doorstep or office</p>
            </div>
            <div className="punch-card">
              <div className="punch-icon">
                <i class="bi bi-pause-circle-fill"></i>
                <i class="bi bi-play-fill"></i>
              </div>

              {/* <h3>Bottled in Glass</h3> */}
              <p>
                Subscribers can pause & resume the subscription at their
                convenience
              </p>
            </div>
          </div>
        </div>
        <div className="fruitpunch-left">
          {/* <img src={pnuch} /> */}
          <h1>
            Our <span>Trial Pack</span>
          </h1>
          <div className="trial-pack-cards">
            <div className="trial-pack-card">
              <h3>1 Time</h3>
              <p>
                Once per product,
                <br /> per user
              </p>
            </div>
            <div className="trial-pack-card">
              <h3>6</h3>
              <p>
                6 products in the Trial pack
                <br /> with all the features
              </p>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <OurProduct />

      <br />
      <br />
      <Banner />
      <div className="second-banner">
        <div className="second-banner-left">
          <img src={leaf} />
          <br />
          <h1>
            If we <span>do it</span>,<br /> we do it <span>right!</span>
          </h1>
          <br />

          <p>
            Beginning with the careful selection of fruits, we ensure that the
            journey of fruits throughout the production, packaging and
            delivering our flavorful juices to your doorstep is marked by
            uncompromising hygiene and efficiency.
          </p>
          <br />
          <br />

          <div className="calories-count-div">
            <div className="calories-count">
              <div className="no">
                <h1>
                  N<span>O</span>
                </h1>
                <p>
                  Sugar
                  <br /> Ice
                  <br /> Water
                  <br /> Preservatives{" "}
                </p>
              </div>
            </div>
          </div>
          <br />
          <br />
          {/* <button>KNOW MORE</button> */}
        </div>
        <div className="second-banner-right">
          <img src={banner2} />
        </div>
      </div>

      <br />
      <br />

      {/* <ProductCategories /> */}
      <br />
      <br />
      <div className="products-div">
        <br />
        <br />
        <br />
        <h1>
          <span>Juice</span> Collections
        </h1>
      </div>

      <Products />

      {/* <br />
      <br />
      <Testimonials /> */}
      {/* <br />
      <br /> */}
      {/* <Blogs />
      <br />
      <br /> */}
      <Footer />
    </>
  );
}

export default Home;
