import React from 'react'
import orange from '../Assets/pngwing.com (33) 1.png'
import newlogo from '../Assets/newlogo11.png'
import img from '../Assets/output-onlinegiftools.gif'
import img2 from '../Assets/output-onlinegiftools2.gif'
import img3 from '../Assets/bowl.gif'
import mission from '../Assets/mission.png'
import { useNavigate } from 'react-router-dom'
function MissionFact() {
  const navigate = useNavigate()
  return (
    <>
    <div className='misson-div'>
    {/* <img className='ig1 ig11' src={}/> */}
    <img className='ig2 ig22' src={img2}/>
    <img className='ig3 ig3121' src={img}/>


  <div className='misson-div-left'>
  <div className='newlogo-div'>
<img className='juice-logo' src={newlogo}/>
    <img className='mission-logo' src={mission}/>
    {/* <div className='misson-logo'>
    </div> */}
  </div>
    <h2 className='doyouknow'>DO YOU KNOW!!!</h2>

  </div>
  <div className='misson-div-right'>
  <div className='mission-clip mission-clip2'>
  <img className='mission-clip212' src={img3}/>
  
 <div className='missionpara'>
    <p>You have to consume a minimum of 400g  of fruit or vegetable everyday as per WHO and ICMR recommendation</p>
 </div>
 <br/>
<p className='fruitconsumer'>Are you a regular fruit consumer?</p>

<div className='mission-btns'>
    <button className='mission-btns-yes' onClick={()=>navigate('/mission500/choice')}>Yes</button>
    <button className='mission-btns-no' onClick={()=>navigate('/mission500/concerning')} >No</button>

</div>

  </div>

  </div>
    

    </div>
   </>
  )
}

export default MissionFact