import React from 'react'
import orange from '../Assets/pngwing.com (34) 1.png'
import newlogo from '../Assets/newlogo11.png'
import mission from '../Assets/mission.png'
import img from '../Assets/output-onlinegiftools.gif'
import img2 from '../Assets/output-onlinegiftools2.gif'
import img3 from '../Assets/bowl.gif'
import { useNavigate } from 'react-router-dom'
function MissionConcerning() {
  const navigate = useNavigate()
  return (
    <>
    <div className='misson-div'>
    <img className='ig1 ig11' src={img}/>
    <img className='ig2 ig22' src={img2}/>
    <img className='ig3' src={img3}/>


  <div className='misson-div-left'>
  <div className='newlogo-div'>
  <img className='juice-logo' src={newlogo}/>
  <img className='mission-logo' src={mission}/>
  </div>
    <h2>That’s Concerning!!!</h2>

  </div>
  <div className='misson-div-right'>
  <div className='mission-clip mission-clip2'>
  <img src={orange}/>
  
 <div className='missionpara'>
    <p>But No worries, We got you covered!</p>
 </div>
 <br/>
<p className='fruitconsumer'>Are Your Ready to make 500g of Fresh Fruit a part of your daily routine with Mission 500?</p>
<br/>
<div className='mission-btns'>
    <button style={{width:'100%'}} className='mission-btns-yes' onClick={()=>navigate('/mission500/choice')}>Yes</button>
    {/* <button className='mission-btns-no' onClick={()=>navigate('/mission500/choice')}>No</button> */}

</div>
<br/>
  </div>

  </div>
    

    </div>
   </>
  )
}

export default MissionConcerning