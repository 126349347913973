import React, { useEffect, useState } from "react";
import orange from "../Assets/pngwing.com (34) 1.png";
import img from '../Assets/output-onlinegiftools.gif'
import img2 from '../Assets/output-onlinegiftools2.gif'
import img3 from '../Assets/bowl.gif'
import img4 from "../Assets/cd1.png";
import newlogo from "../Assets/newlogo11.png";
import icon from "../Assets/righticon.png";
import axios from "axios";
import { baseUrl } from "../utils/data";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import mission from '../Assets/mission.png';
import img0 from '../Assets/neworg.gif'
function MissionChoice() {
  const navigate = useNavigate();
  const [category, setCategory] = useState(null); // Set initial state to null
  const [idCategory, setIdCategory] = useState("");

  const fetchdata = async () => {
    try {
      const res = await axios.get(`${baseUrl}/category/all`);
      if (res.status === 200) {
        setCategory(res?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
      toast.error("Failed to load categories");
    }
  };

  useEffect(() => {
    fetchdata();
  }, []);

  const handleCategoryClick = (categoryId) => {
    setIdCategory(categoryId);
    handleToNavigate(categoryId);
  };

  const handleToNavigate = (categoryId) => {
    if (!categoryId) {
      return toast.error("Please select any category.");
    }
    navigate(`/mission500/awesome/${categoryId}`);
  };

  return (
    <>
      <div className="misson-div misson-div2">
        <img className="ig1 ig11" src={img} alt="Background Orange" />
        <img className="ig2 ig22" src={img2} alt="Pineapple Juice" />
        <img className="ig3" src={img3} alt="New Item" />

        <div className="misson-div-left">
          <div className="newlogo-div">
            <img className="juice-logo" src={newlogo} alt="Juice Logo" />
            <img className="mission-logo" src={mission} alt="Mission Logo" />
          </div>
          <h2>That’s a great Choice!!</h2>
          <img className="slidingorange" src={img0}/>
          
          <br/>
        </div>

        <div className="misson-div-right misson-div-right2">
          <div className="mission-clip3">
            <div className="Awesome-text">
              <p>
                You clearly have a great approach to balanced intake, and we’re here to make it effortlessly easy and surprisingly affordable for you.
              </p>
            </div>
            <br />

            <h4>Try Our Products Now!!</h4>
            <br />

            <div className="asw-cards">
              {category === null ? (
                <p>Loading...</p>
              ) : category.length > 0 ? (
                category.map((item) => (
                  <div
                    key={item._id}
                    className={`asw-card ${idCategory === item._id ? "selected" : ""}`}
                    onClick={() => handleCategoryClick(item._id)}
                  >
                    <img src={img4} alt={item.name} />
                    <p>{item.name}</p>
                  </div>
                ))
              ) : (
                <p>No categories found</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MissionChoice;
