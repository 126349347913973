import React from 'react'
import newbanner from '../Assets/newbanner.png'

function MissionDeliveryAddress() {
  return (
  <>
    <div>

<h2>Fruit Juices</h2>
<div className='slot-banner'>
<img src={newbanner}/>
<div className='slot-banner-up'>
<p>Trail / 1m</p>
<h3>Rs 650 / 2599</h3>
{/* <p>Slot - Morning</p>
<p>Start Date - 20/02/2024</p> */}
</div>

</div>


</div>
  <div>
                <div>
           <div className='mission-slot-day-eveing'>
<h3 style={{textAlign:'left'}}>User Details</h3>
<br/>

<div className='mission-numbver'>
    <label >Full Name</label>
    <input style={{marginTop:'15px'}} placeholder='Enter Name' />
</div>

<br/>


<div className='mission-numbver'>
    <label >Phone Number</label>
    <input style={{marginTop:'15px'}} placeholder='Enter Number' />
</div>
<br/>


<div className='mission-numbver'>
    <label >Email</label>
    <input style={{marginTop:'15px'}} placeholder='Enter Email' />
</div>
</div>
      <br/>

      {/* <button className='missionloginbtn'>Continue</button> */}

            </div>
            </div>
  </>
  )
}

export default MissionDeliveryAddress