import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // To navigate to the next page
import orange from "../Assets/pngwing.com (34) 1.png";
import img from '../Assets/output-onlinegiftools.gif'
import img2 from '../Assets/output-onlinegiftools2.gif'
import img3 from '../Assets/bowl.gif'
import img4 from "../Assets/cd1.png";
import newlogo from "../Assets/newlogo11.png";
import icon from "../Assets/righticon.png";
import newbanner from "../Assets/newbanner.png";
import mission from '../Assets/mission.png'

function MissionSubscribe() {
  const [selectedProduct, setSelectedProduct] = useState(null); // To store the product data from localStorage
  const [subscriptionType, setSubscriptionType] = useState(""); // To store subscription type
  const navigate = useNavigate(); // To navigate to the next page

  // Retrieve product data from localStorage when the component mounts
  useEffect(() => {
    const storedProduct = JSON.parse(localStorage.getItem("selectedProduct"));
    if (storedProduct) {
      setSelectedProduct(storedProduct);
    }
    if (storedProduct.subscriptionType) {
      setSubscriptionType(storedProduct.subscriptionType);
    }
  }, []);

  // Function to handle subscription selection
  const handleSubscription = (type) => {
    setSubscriptionType(type); // Set the subscription type in state
  };

  // Function to handle "Continue" button click
  const handleContinue = () => {
    if (selectedProduct) {
      // Add or update the subscriptionType in the product data
      const updatedProduct = { ...selectedProduct, subscriptionType };
      localStorage.setItem("selectedProduct", JSON.stringify(updatedProduct)); // Save the updated product to localStorage
      console.log("Updated Product:", updatedProduct);

      // Navigate to the next page
      navigate("/mission500/slot"); // Replace "/nextpage" with your desired route
    } else {
      alert("No product selected!");
    }
  };

  return (
    <>
      <div className="misson-div misson-div2">
        <img className="ig1 ig11" src={img} />
        <img className="ig2 ig22" src={img2} />
        <img className="ig3" src={img3} />

        <div className="misson-div-left">
          <div className="newlogo-div">
          <img className='juice-logo' src={newlogo}/>
          <img className='mission-logo' src={mission}/>
          </div>
          <h2>{selectedProduct?.name||""}</h2>
        </div>
        <br/>    <br />
        <br />
        
          

        <div className="misson-div-right">
          <div className="mission-clip3">
            {/* <div className="mission-banner">
              <img src={selectedProduct?.imageUrl||newbanner} />
            </div> */}
            {/* <br />
            <br />
            <br />
            <br /> */}
        
            {/* Show selected product details */}
            {selectedProduct ? (
              <div className="selected-product-details">
              <div className="mission-fact mission-fact121">
              
              <p>
                <img src={icon} /> Juice Fact
              </p>
              <p>
                <img src={icon} /> Cold-pressed Fact
              </p>
              <p>
                <img src={icon} /> Free Delivery
              </p>
            </div>
            <br />

                <h4>Selected Product</h4>
                <br/>
                <div className="selectedpd-div">

                <div className="selectedpd">

                <img src={selectedProduct.imageUrl || img4} alt="Selected Product" width={"90px"} height={"90px"} />
        
               
                </div>
             <div>
             <p ><b>Name: </b>{selectedProduct.name}</p>
                {/* <p style={{marginTop:'5px'}}>Price: Rs {selectedProduct.price}</p> */}
                <p style={{marginTop:'5px'}}><b>Subscription Type:</b> {selectedProduct.subscriptionType || "Not selected"}</p>
             </div>
                </div>
              </div>
            ) : (
              <p>No product selected.</p>
            )}

          

         
            <br />

            <h4>Subscribe Now!!!</h4>
            <br />
            <div className="subscribe-btns">
              {/* Subscription buttons */}
              <button
                className={`month-trial ${subscriptionType === "Monthly" ? "active" : ""}`}
                onClick={() => handleSubscription("Monthly")}
              >
                1 Month - 26 Products
              </button>
              <button
                className={`month-trial ${subscriptionType === "Trial" ? "active" : ""}`}
                onClick={() => handleSubscription("Trial")}
              >
                Trial - 6 Products
              </button>
            </div>
<br/>
            {/* Display selected subscription type */}
            {subscriptionType && (
              <p>
                You have selected the <strong>{subscriptionType}</strong> subscription.
              </p>
            )}
            <br/>

            {/* Continue button */}
            <button className="continue-button" onClick={handleContinue}>
              Continue
            </button>
<br/>

          </div>
        </div>
      </div>
    </>
  );
}

export default MissionSubscribe;








// import React from "react";
// import orange from "../Assets/pngwing.com (34) 1.png";

// import img from "../Assets/967_417_Orange_Color_Stock_Photos__High-Res_Pictures__and_Images-removebg-preview.png";
// import img2 from "../Assets/Pineapple_Juice-removebg-preview.png";
// import img3 from "../Assets/new-removebg-preview.png";
// import img4 from "../Assets/cd1.png";
// import newlogo from "../Assets/newlogo11.png";
// import icon from "../Assets/righticon.png";
// import newbanner from "../Assets/newbanner.png";
// function MissionSubscribe() {
//   return (
//     <>
//       <div className="misson-div">
//         <img className="ig1 ig11" src={img} />
//         <img className="ig2 ig22" src={img2} />
//         <img className="ig3" src={img3} />

//         <div className="misson-div-left">
//           <div className="newlogo-div">
//             <img src={newlogo} />
//             <div className="misson-logo">
//               <p>
//                 Mission
//                 <br />
//                 500
//               </p>
//             </div>
//           </div>
//           <h2>Fruit Juices</h2>
//         </div>
//         <div className="misson-div-right">
//           <div className="mission-clip3">
//             <div className="mission-banner">
//               <img src={newbanner} />
//             </div>
//             <br />

//             <div className="mission-fact">
//               <p>
//                 <img src={icon} /> Juice Fact
//               </p>
//               <p>
//                 <img src={icon} /> Cold-pressed Fact
//               </p>
//               <p>
//                 <img src={icon} /> Free Delivery
//               </p>
//             </div>
//             <br />

//             <h4>Subscribe Now!!!</h4>
//             <br />
//             <div className="subscribe-btns">
//               <button className="month-btn">1 Month - 26 Products</button>
//               <button className="month-trail">Trail - 6 Products</button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default MissionSubscribe;
