import React from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Navbar from '../Components/Navbar';
import imgSuccess from '../Assets/CheckCircle.svg';
import imgFailed from '../Assets/global_hint_failure_595796 (1).png';
import imgOnTheWay from '../Assets/undraw_On_the_way_re_swjt 1.svg';
import Footer from '../Components/Footer';
import { Link } from 'react-router-dom';

function PaymentSuccessful() {
  const location = useLocation();
  const { status } = queryString.parse(location.search);

  return (
    <>
    <Navbar />
    <br />
    <br />
    <div className='payment-status'>
      <img style={{height:'200px'}} src={status === 'success' ? imgSuccess : imgFailed} alt={status === 'success' ? 'Payment Successful' : 'Payment Failed'} />
      <br />
      <h1>{status === 'success' ? 'Success' : 'Whoops!'}</h1>
      <p>{status === 'success' ? 'Its our pleasure to offer you our product' : 'Payment Failed'}</p>

      {status === 'success' && (
        <>
          <br />
          <br />
          <br />
          <img src={imgOnTheWay} alt='On the way' />
        </>
      )}
      <br />
      <br />
      <br />
      {status === 'success' ? (
          <Link to='/Dashboard'>
            <button>BACK TO HOME</button>
          </Link>
        ) : (
          <Link to='/CheckOut'>
            <button>Try Again</button>
          </Link>
        )}
      <br />
      <br />
      <br />
    </div>
    <br />
    <Footer />
  </>
  )
}

export default PaymentSuccessful