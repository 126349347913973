import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../utils/data";
import { useNavigate, useParams } from "react-router-dom";

function SubProduct() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { id } = useParams();
  const [allSub, setAllSub] = useState([]);

  const navigate = useNavigate();

  const getData = async () => {
    try {
      const res = await axios.get(`${baseUrl}/subproduct/all/${id}`);
      if (res?.status === 200) {
        setAllSub(res?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const truncateDescription = (description) => {
    return description.length > 20
      ? description.slice(0, 20) + "..."
      : description;
  };

  return (
    <>
      <div className="products-div">
        <div className="product-cards">
          {allSub.map((product, index) => (
            <div key={product._id} className="product-card product-card2">
              <img src={product.images?.image1} alt={product.name} />

              <br />
              <p>{product.name}</p>
              {/* <p>{truncateDescription(product.description)}</p> */}
              <button
                onClick={() => navigate(`/SubProductDetails/${product?._id}`)}
              >
                VIEW MORE
              </button>
            </div>
          ))}
        </div>
        <br />
        <br />
        <br />
        {/* <button className="viewallbtn">VIEW ALL</button> */}
        <br />
        <br />
      </div>
    </>
  );
}

export default SubProduct;
