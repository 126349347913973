import React, { useState } from "react";
import Navbar from "../Components/Navbar";
import img1 from "../Assets/image 202.svg";
import Footer from "../Components/Footer";
import SingleSubProductDetail from "../Components/SingleSubProductDetail";

function SubProductDetails() {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <>
      <Navbar />
      {/* <div className="pd-banner"></div> */}

      <SingleSubProductDetail />
{/* 
      <div className="Description">
        <div className="tab-container">
          <div className="tabs">
            <button
              className={activeTab === 1 ? "tab-btn active" : "tab-btn"}
              onClick={() => handleTabChange(1)}
            >
              Description
            </button>
            <button
              className={activeTab === 2 ? "tab-btn active" : "tab-btn"}
              onClick={() => handleTabChange(2)}
            >
              Shipping Information
            </button>
            <button
              className={activeTab === 3 ? "tab-btn active" : "tab-btn"}
              onClick={() => handleTabChange(3)}
            >
              Reviews
            </button>
          </div>
          <div className="tab-content">
            {activeTab === 1 && (
              <>
                <div className="tab-div">
                  <div className="tab-div-left">
                    <h1>START YOUR HEALTHY DAY WITH JUICE.</h1>
                    <br />
                    <p>
                      Our products come from hundreds of farms, and when they
                      were gathered, the best fruits from each farm were
                      selected. The chosen people are whittled down to nothing.
                      The juices are made by combining these pulps with our
                      top-secret components. It has no chemical coloring or
                      preservatives of any kind. Our fruit juices are loaded
                      with vitamins and minerals. The ideal way to take them is
                      through freshly squeezed juices, and the juices of
                      vegetables and fruits with the highest antioxidant
                      concentrations provide the best defense against damage
                      brought on by unstable chemicals that naturally exist in
                      your body.
                    </p>
                  </div>
                  <div className="tab-div-right">
                    <img src={img1} />
                  </div>
                </div>
                <br />
                <br />
                <br />
                <br />
                <div className="tab-div">
                  <div className="tab-div-right">
                    <img src={img1} />
                  </div>
                  <div className="tab-div-left">
                    <h1>START YOUR HEALTHY DAY WITH JUICE.</h1>
                    <br />
                    <p>
                      Our products come from hundreds of farms, and when they
                      were gathered, the best fruits from each farm were
                      selected. The chosen people are whittled down to nothing.
                      The juices are made by combining these pulps with our
                      top-secret components. It has no chemical coloring or
                      preservatives of any kind. Our fruit juices are loaded
                      with vitamins and minerals. The ideal way to take them is
                      through freshly squeezed juices, and the juices of
                      vegetables and fruits with the highest antioxidant
                      concentrations provide the best defense against damage
                      brought on by unstable chemicals that naturally exist in
                      your body.
                    </p>
                  </div>
                </div>
              </>
            )}
            {activeTab === 2 && <></>}
          </div>
        </div>
      </div> */}

      {/* <div className="products-div">
        <h1>
          Recommended <span>Product</span>{" "}
        </h1>
      </div> */}
      {/* <Products /> */}
      <Footer />
    </>
  );
}

export default SubProductDetails;
