import React from 'react'
import Navbar from '../Components/Navbar'

import img from '../Assets/about us 1.png'
import img2 from '../Assets/about us 2.png'
import Footer from '../Components/Footer'
function About() {
  return (
   <>
    <Navbar/>
    <div className='aboutbanner'>
    <div className='aboutbannercolor'>
    <h1>ABOUT US</h1>
<p>When it comes to choosing between healthy or easy, the choice often inclines towards easy.<br/> Our story started when we thought of making the healthier choice easy.


</p>
    </div>

    </div>
    <div className='aboutthejuices'>
    <div className='aboutthejuices-left'>
    <h3>Our Services</h3>
  
    <p>There is no purest form of juice rather than a cold pressed juice and that is what we are going to provide you to your doorstep daily with our attractive monthly subscription packs with the focus on including balanced nutritious options. Our specifically curated subscription packs help you reduce the juice consumption constraints taking you a bit closer towards the healthy awakening. Subscribe today for a happier you.


</p>

</div>
 <div className='aboutthejuices-right'>
<img src={img}/>
</div>
    </div>
    <div className='ourstory'>
    <h3>Our Mission</h3>
<br/>
<br/>

    {/* <h1>Real Fruit Tastes Real with Every Sip</h1> */}
<img src={img2}/>
<br/>
<div className='ourstory-p'>
<p>While it comes to Food consumption in Metropolitan cities, perishable food takes the high place which often leads to an unbalanced diet. It's helpful to try to make balanced, varied, and nourishing choices as part of a healthy diet. What's more healthier than fruits? Though the thought of eating fruits appears to be simple and beneficial, it goes overlooked in the long and tedious days. What if there is an easier and healthier way to consume these nutritious fruits in the form of juicy delights? That is exactly what "TheJuices.co" provides you, A simple and efficient way to consume the healthy natural cold pressed juices indulging you in the journey of deliciousness.


</p>

</div>
    </div>
    <Footer />
   </>
  )
}

export default About