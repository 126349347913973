import React, { useState, useRef, useEffect } from "react";
import pineapple from "../../Assets/pianapple.svg";
import axios from "axios";
import { baseUrl } from "../../utils/data";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import load from '../../Assets/newlogo.png'

const OurProduct = () => {
  const [loading, setLoading] = useState(true); // Add loading state
  const navigate = useNavigate();
  const [allProduct, setAllProduct] = useState(null);

  const getAllProduct = async () => {
    try {
      const res = await axios.get(`${baseUrl}/product/all`);
      setAllProduct(res?.data?.data);
    } catch (error) {}
    finally {
      setLoading(false); // Set loading to false once data is fetched
    }
  };

  useEffect(() => {
    getAllProduct();
  }, []);

  const handleAddtoCart = async (productId) => {
    try {
      const res = await axios.post(`${baseUrl}/cart`, { productId, quantity: 1 }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      if (res?.status === 200) {
        toast.success("Product Added to Cart.");
      }
    } catch (error) {
      if (error?.response?.data?.message === "User not authorized") {
        toast.error("Please Login Again.");
        navigate("/Login");
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const formatPrice = (price) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  if (loading) {
    return (
      <div className="loader-div">
       {/* <span class="loader"></span> */}
       <img src={load} alt="Logo" class="loader-logo"/>
       <div class="spinner"></div>
        {/* <img src={load}/> */}
        
      </div>
    );
  }

  return (
    <div className="our-product-div">
      <h1>
        Our <span>Products</span>
      </h1>
      <br />
      <br />
      <div className="homeproduct-cards">
       <div className="homeproduct-cards-div">
       {allProduct && allProduct.length>0 && allProduct.map((product) => (
          <div key={product._id} className="homeproduct-card" onClick={()=>navigate(`/ProductDetails/${product?._id}`)}>
            <img src={product.imageUrl || pineapple} alt={product.name}  />
            <p>{product.name}</p>
            <h3>Rs {formatPrice(product.price)}.00 / month</h3>
            <button >Subscribe</button>
          </div>
        ))}
       </div>
        {/* <div className="homeproduct-card">
        <img src={pineapple} />
        <p>Pineapple Juice</p>
        <h3>Rs 200.00</h3>
        <button>ADD TO CART</button>
      </div>
      <div className="homeproduct-card">
        <img src={pineapple} />
        <p>Pineapple Juice</p>
        <h3>Rs 200.00</h3>
        <button>ADD TO CART</button>
      </div>
      <div className="homeproduct-card">
        <img src={pineapple} />
        <p>Pineapple Juice</p>
        <h3>Rs 200.00</h3>
        <button>ADD TO CART</button>
      </div>
      <div className="homeproduct-card">
        <img src={pineapple} />
        <p>Pineapple Juice</p>
        <h3>Rs 200.00</h3>
        <button>ADD TO CART</button>
      </div>
      <div className="homeproduct-card">
        <img src={pineapple} />
        <p>Pineapple Juice</p>
        <h3>Rs 200.00</h3>
        <button>ADD TO CART</button>
      </div> */}
      </div>
    </div>
  );
};

export default OurProduct;
