import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../utils/data";
import load from "../../Assets/newlogo.png";


const DeliveryProductDialog = ({ show, handleClose, subscription }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    startDate: "",
    deliveryAddress: "",
    slot: "",
    packageType: "",
    remain: 0,
    deliveryLocation: "",
    orderAmount: 0,
    packingAmount: 0,
    subscriptionId:"",
  });
  const [loading, setLoading] = useState(false);
  const [addressId, setAddressId] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [productData, setProductData] = useState(null);
  const [toggle, setToggle] = useState(false)
  console.log(addresses);
  console.log(subscription);
  console.log(formData);
  
  const addDaysExcludingSunday = (date, daysToAdd) => {
    let count = 0;
    let newDate = new Date(date);

    while (count < daysToAdd) {
      newDate.setDate(newDate.getDate() + 1);

      // Check if the day is not Sunday (0 is Sunday)
      if (newDate.getDay() !== 0) {
        count++;
      }
    }

    return newDate;
  };

  const handlePreUpdate = (ele) => {
    const today = new Date();
    const remain = ele.remain || 0;
  
    const newStartDate = addDaysExcludingSunday(today, remain);
  
    setFormData({
      ...formData,
      slot: ele.slot,
      packageType: ele.packageType,
      deliveryAddress: ele.deliveryAddress,
      deliveryLocation: ele.deliveryLocation,
      startDate: newStartDate.toISOString().split("T")[0], // Format date as YYYY-MM-DD
    });
  };
  const fetchAddresses = async () => {
    try {
      const response = await axios.get(`${baseUrl}/user/bytoken`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const add = response?.data?.data?.deliveryAddress
      const findAddress = add.find(e=> `${e.address.trim()} ${e.pincode}`== formData.deliveryAddress.trim())
      setAddressId(findAddress?._id || null)
      setAddresses(add);
    } catch (error) {
      console.error("Error fetching addresses:", error);
    }
  };

  console.log(addressId);
    const fetchProductData = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/product/single/${subscription?.product?._id}`
        );
        if (response.status == 200) {
          setProductData(response?.data?.data);
          calculateOrderAmount(response?.data?.data);
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

  useEffect(() => {
    if (subscription) {
      fetchAddresses();
      fetchProductData();
      handlePreUpdate(subscription)
    }
  }, [subscription]);

  useEffect(()=>{
    setToggle(!toggle)
  },[addresses])



  const calculateOrderAmount = (product) => {
    if (subscription.isTrial) {
      setFormData((prevData) => ({
        ...prevData,
        orderAmount: product.price - subscription.orderAmount,
        remain: 21,
        subscriptionId:subscription._id
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        orderAmount: product.price,
        remain: 26,
      }));
    }
  };

  const handleSelectAddress = (event) => {
    const addressId = event.target.value;
    const selectedAddress = addresses.find(
      (address) => address._id === addressId
    );
    setFormData({
      ...formData,
      deliveryAddress: `${selectedAddress.address} ${selectedAddress.pincode}`,
      deliveryLocation: selectedAddress.location,
    });
    setAddressId(addressId);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateFormData = () => {
    const {
      startDate,
      deliveryAddress,
      slot,
      packageType,
      orderAmount,
      deliveryLocation,
    } = formData;

    if (
      !startDate ||
      !deliveryAddress ||
      !slot ||
      !packageType ||
      !orderAmount ||
      orderAmount <= 0 ||
      !deliveryLocation.lat ||
      !deliveryLocation.lng
    ) {
      toast.error("Please fill in all required fields correctly.");
      return false;
    }
    return true;
  };


  const handleSubmit = async () => {
    
    if (!validateFormData()) {
        return;
      }
      setLoading(true)
    try {
      const res = await axios.post(
        `${baseUrl}/subscription/renewplan`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (res?.status === 200) {
        const { batchId: orderId, orderAmount, packingAmount } = res.data.data;
        const result = await axios.post(`${baseUrl}/phonepe/phoneperequest`, {
          amount: Number(orderAmount) + Number(packingAmount || 0),
          merchantUserId: orderId,
        });
        if (result?.status === 200) {
          window.location.href = result.data;
        }
      }
    } catch (error) {
      toast.error("Failed to renew subscription.");
    }
    setLoading(false)
    handleClose();
  };

  
  if (loading) {
    return (
      <div className="loader-div">
        <img src={load} alt="Logo" class="loader-logo" />
        <div class="spinner"></div>
      </div>
    );
  }





  return (
    <Dialog open={show} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Renew Subscription</DialogTitle>
      <DialogContent>
        <form>
          <div className="form-group">
            <label>Start Date</label>
            <input
              type="date"
              className="form-control"
              name="startDate"
              value={formData.startDate}
              onChange={handleChange}
            />
          </div>
          <FormControl fullWidth margin="normal">
            <InputLabel>Delivery Address</InputLabel>
            <Select
              value={addressId}
              onChange={handleSelectAddress}
              name="deliveryAddress"
            >
              {addresses.map((address) => (
                <MenuItem key={address._id} value={address._id}>
                  {address.address} {address.pincode}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Package Type</InputLabel>
            <Select
              value={formData.packageType}
              onChange={handleChange}
              name="packageType"
            >
              <MenuItem value="onetimeuse">Plastic Bottle</MenuItem>
              {/* <MenuItem value="Premium">Premium</MenuItem> */}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Slot</InputLabel>
            <Select value={formData.slot} onChange={handleChange} name="slot">
              <MenuItem value="Morning">Morning</MenuItem>
              <MenuItem value="Evening">Evening</MenuItem>
            </Select>
          </FormControl>
          <p>Address: {formData?.deliveryAddress}</p>
          <p>
            Location: Lat{formData.deliveryLocation.lat} Lng
            {formData.deliveryLocation.lng}
          </p>
          <p>Order Amount: {formData.orderAmount}</p>
          {/* <p>Packing Amount: {formData.packingAmount}</p> */}
          <p>Days Remaining: {formData.remain}</p>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Renew
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeliveryProductDialog;
