import React from 'react'
import Navbar from '../Components/Navbar'
import paymentsrc from '../Assets/payment method2.svg'
import Footer from '../Components/Footer'

function Payment() {
  return (
    <>
        <Navbar/>
        {/* <div className='pd-banner'></div> */}
        <br/>
        <br/>
    
        <h1 className='check-heading' style={{textAlign:'center'}}>Payment</h1>
        <br/>
        <br/>
        <div className='payment-div'>
        <div className='payment-div-left'>
        <h2>Billing Address</h2>
        <div className='line2'></div>
        <br/>
        <div className='payment-check'>
    <input type='checkbox'/>
    <p>Default (same as billing address)</p>
 </div>
 <br/>
        <div className='payment-check'>
    <input type='checkbox'/>
    <p>Default (same as billing address)</p>
 </div>
 <br/>
<div className='payment-ips'>
    <input placeholder='Name'/>
    <input placeholder='Email'/>
    <input placeholder='Country'/>
    <input placeholder='Address Line 1'/>
    <input placeholder='Address Line 2'/>
    <input placeholder='City / Suburb'/>
    <input placeholder='Zip / Postcode'/>
    <input placeholder='Phone'/>

</div>

</div>
 <div className='payment-div-right'>
 <h2>Billing Address</h2>
 <div className='line2'></div>
<br/>
<p>Please choose your payment method</p>
<br/>
<img className='paym' src={paymentsrc}/>
<br/>
<br/>
<br/>

<div className='card-details'>
    <p>Card Number*</p>
    <input/>
</div>
<br/>

<div className='card-details card-details2'>
    <p>Card Number*</p>
    <input/>
   

</div>

<br/>

<div className='card-details'>
    <p>Card Number*</p>
    <input/>
</div>
<br/>
<br/>
<br/>

<button>PAY AND PLACE ORDER</button>

<br/>
<br/>
<br/>
<p>By clicking on ‘pay and place order’,you agree (i) to make your purchease from global -e as marchant of record for this transaction, subject to global-e’s  term of sale;(ii) that your information will be handled by global-e in accordance with the global-e privacy policy; and (iii) that global-e will share your information (excluding the payment details)with modimal.</p>
</div>

        </div>
        <Footer/>
    </>
  )
}

export default Payment