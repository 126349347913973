import React from 'react'
import store1 from '../Assets/Download our app-1.svg'
import store2 from '../Assets/Download our app.svg'
import phone from '../Assets/phone.svg'

function Banner2() {
  return (
   <>
   <div className='download-banner'>
   <div className='download-banner-left'>
   <h1> <span>The Juices</span></h1>

   <h1>Experience <span>Juices </span> like Never Before.</h1>
   <br/>
   <p>Thoughtfully curated subscription plans just for you.
Track & monitor your juice and nutrient intake. 
Take control of your subscriptions to pause/resume at your convenience from our user-friendly and Intuitive App. Download now!
</p>
<br/>
<h4>Coming Soon</h4>
<br/>

<div className='store-btn'>
    <img src={store1}/>
    <img src={store2}/>

</div>

   </div>
   <div className='download-banner-right'>
<img src={phone}/>
</div>
   </div>

   </>
  )
}

export default Banner2