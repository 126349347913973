import orange from '../Assets/mission.png'
import React from 'react'
import img from '../Assets/output-onlinegiftools.gif'
import img2 from '../Assets/output-onlinegiftools2.gif'
import img3 from '../Assets/bowl.gif'
import logo from '../Assets/newlogo.png'
import { useNavigate } from 'react-router-dom'
function MissionHome() {
  const navigate = useNavigate()
  return (
   <>
    <div className='misson-div'>
    <img className="ig1 ig11" src={img} alt="Orange" />
      <img className="ig2 ig22" src={img2} alt="Pineapple Juice" />
      <img className="ig3" src={img3} alt="New" />


  <div className='misson-div-left'>
   <img className='logo' src={logo}/>
  </div>
  <div className='misson-div-right'>
  <div className='mission-clip'>
  <img src={orange}/>
  
  
  <h3>Enjoy Your Juice<br/> & Fruits</h3>
  <br/>
  <button onClick={()=>{navigate('/mission500/fact')}} >Get Started</button>

  </div>

  </div>
    

    </div>
   </>
  )
}

export default MissionHome